:root {
    --bg-color: hsl(0, 0%, 95%);
    --header-color: hsl(0, 0%, 100%);
    --input-color: hsl(0, 0%, 52%);
    --h1-color: hsl(200, 15%, 8%);
    --text-color: hsl(200, 15%, 8%);
    --elements-color: hsl(0, 0%, 100%);
    --hover-color: hsla(209, 23%, 22%, 0.13);
    --box-shadow: hsla(181, 0%, 63%, 1);

}

[data-theme="dark"] {
    --bg-color: hsl(207, 26%, 17%);
    --header-color: hsl(209, 23%, 22%);
    --input-color: hsl(0, 0%, 100%);
    --h1-color: hsl(0, 0%, 75%);
    --text-color: hsl(0, 0%, 100%);
    --elements-color:  hsl(209, 23%, 22%);
    --hover-color: hsla(209, 23%, 22%, 0.57);
    --box-shadow: transparent;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-family: 'Nunito Sans', sans-serif;
    color: var(--text-color);
    height: 100%; 
    background-color: var(--bg-color);
}

body {
    height: 100%; 
    font-size: 0.875rem;
    font-weight: 300;
}

h1 {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--h1-color)
}

h2 {
    font-size: 1.2rem;
    font-weight: 800;
}

/* mobile styles */
@media (max-width: 400px) {
    h1 {
        font-size: 0.875rem;
    }
}