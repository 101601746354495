.main-container {
    background-color: var(--bg-color);
    height: 100%;
    padding-top: 3rem;
}

.filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 5rem;
}

.country-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px;
    padding: 0 5rem;
}

.country-link {
    text-decoration: none;
    color: var(--text-color);
}


article > img {
    border-radius: 5px 5px 0 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}  

.country-snapshot {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    width: 100%;
    background-color: var(--elements-color);
}

.name {
    margin-bottom: 1rem;
}

.country-stat {
    display: flex;
    flex-direction: row;
}

.stat-name {
    font-weight: 600;
}

.stat-value {
    margin-left: .5rem;
}

/* mobile styles */
@media (max-width: 400px) {
    .filter-container {
        flex-direction: column;
        font-size: 0.875rem;
        padding: 1.5rem 1rem;
    }

    .country-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .country-grid {
        display: grid;
        grid-template-columns: auto;
        padding: 0 5rem;
    }

    
}