.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 1.5rem 5rem;
    background-color: var(--header-color);
    box-shadow: 0 0 5px -2px var(--box-shadow);
}

.theme-container {
    display: flex;
    flex-direction: row;      
}


.theme-label {
    display: flex; 
    font-size: 1rem;
    font-weight: 600;
    align-items: center;
}

.theme-label, .theme-checkbox {
    cursor: pointer;

}

.theme-checkbox {
    margin-right: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
    appearance: none;
    background: url("./../../public/icon-moon.svg") no-repeat;
}

.theme-checkbox:checked {
    background: url("./../../public/icon-sun.svg") no-repeat;
}

/* mobile styles */
@media (max-width: 400px) {
    .header {
        padding: 1.875rem 1rem;
        box-shadow: 0 0 5px -2px var(--box-shadow);
    }

    .theme-label {
        font-size: 0.75rem;
    }

    .theme-checkbox {
        height: 1.3rem;
        width: 1.3rem;
        background-size: cover;
    }
}