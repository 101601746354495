
.dropbtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--elements-color);
    color: var(--text-color);
    width: 12.5rem;
    height: 3.5rem;
    padding: 1.125rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 300;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 9px -6px var(--box-shadow);
  }

  select {
    background-color: var(--element-color);
    color: var(--text-color);
    padding: 12px;
    width: 200px;
    border: none;
    border-radius: 5px;
  }

    /* mobile styles */
@media (max-width: 400px) {
    .dropbtn {
        font-size: 0.75rem;
        height: 3rem;
    }

    .dropdown-content a {
        font-size: 0.75rem;
    }
}
  